<template>
  <div class="interCon">
    <!-- 我的积分 -->
    <div class="inter_top">
      <div>
        <div class="title">{{ $t("vip.points") }}</div>
        <div class="time_inter">
          <div>
            <p class="red">
              <span class="blod">{{ formatIntegral(w_overdueintegral) }}</span
              >&nbsp;{{ $t("vip.expiring_points") }}
            </p>
            <div class="inter">
              <div class="in_item has" :style="{ width: hasiw }">
                <span class="blod">{{ formatIntegral(hasIntergral) }}</span>
                {{ $t("vip.available_points") }}
                <span v-if="soonIntergral != 0">{{ hasiw }}</span>
              </div>
              <div class="in_item soon" :style="{ width: soonw }">
                <span class="blod">{{ formatIntegral(soonIntergral) }}</span>
                {{ $t("vip.waiting_points") }}
                <span v-if="soonIntergral != 0">{{ soonw }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tip_txt" style="white-space: pre-line">
      {{ $t("vip.tip1") }}
    </div>
    <div class="interList">
      <div class="name">
        <span>{{ $t("vip.active") }}</span>
        <span>{{ $t("vip.date") }}</span>
        <span>{{ $t("vip.points") }}</span>
        <span>{{ $t("vip.status") }}</span>
      </div>
      <div class="list">
        <div
          :class="['l_item', item.status == 2 ? 'gray' : '']"
          v-for="(item, i) in interlist"
          :key="i"
        >
          <div>{{ $t("vip.shop_order") }}{{ item.order_no }}</div>
          <div>
            <div>{{$t('vip.order_date')}}{{ item.adddate2 }}</div>
            <div v-if="item.times">{{$t('vip.posting_date')}}{{ item.times }}</div>
          </div>
          <div>
            <span class="blod">{{ formatIntegral(item.integral) }}</span>
          </div>
          <!-- <div v-if="item.type == 2">
            {{
              item.status === "1"
                ? $t("vip.available_points")
                : item.status === "2"
                ? $t("vip.waiting_points")
                : item.status === "3"
                ? $t("vip.about_expire")
                : ""
            }}
          </div> -->
          <div class="status">
            {{ $i18n.locale == "en" ? item.confirm_dateen : item.confirm_date }}
          </div>
        </div>
      </div>
    </div>
    <m-empty v-if="!interlist.length"></m-empty>
  </div>
</template>

<script>
import mEmpty from '@/components/Empty.vue'
import { getPoints } from '@/api/my'
import { formatIntegral } from '@/utils/filterData.js'

export default {
  components: {
    mEmpty
  },
  data () {
    return {
      interlist: [],
      hasIntergral: 0, // 已入积分
      soonIntergral: 0, // 待入积分
      w_overdueintegral: 0 // 快过期
    }
  },
  computed: {
    hasiw () {
      return (
        Math.round(
          (this.hasIntergral / (this.hasIntergral + this.soonIntergral)) * 100
        ) + '%'
      )
    },
    soonw () {
      return (
        Math.round(
          (this.soonIntergral / (this.hasIntergral + this.soonIntergral)) * 100
        ) + '%'
      )
    }
  },
  methods: {
    async getPoint () {
      const res = await getPoints()
      this.interlist = res.integrallist
      this.w_overdueintegral = res.w_overdueintegral
      this.hasIntergral = parseInt(res.integral)
      this.soonIntergral = parseInt(res.waitintegral)
    }
  },
  mounted () {
    this.getPoint()
  },
  setup () {
    return {
      formatIntegral
    }
  }
}
</script>

<style lang="scss" scoped>
.interCon {
  .inter_top {
    border: 1px solid #a8a8a8;
    border-radius: 5px;
    & > div:nth-of-type(1) {
      padding: 18px 25px;
    }
    .title {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .time_inter {
      display: flex;
      .red {
        color: red;
        text-transform: uppercase;
      }
      & > div {
        flex: 1;
      }
      .inter {
        font-size: 12px;
        display: flex;
        .in_item {
          min-width: 100px;
          padding-top: 10px;
          text-align: center;
          span {
            white-space: nowrap;
          }
        }
        .has {
          width: 40%;
          margin-right: 10px;
          &::before {
            content: "";
            display: inline-block;
            width: 100%;
            height: 16px;
            background-color: #000000;
            margin-bottom: 10px;
          }
        }
        .soon {
          width: 60%;
          &::before {
            content: "";
            display: inline-block;
            width: 100%;
            height: 16px;
            background-color: #a8a8a8;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .tip_txt {
    line-height: 20px;
    margin-top: 17px;
    margin-bottom: 55px;
    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
  .interList {
    .name {
      display: flex;
      border-bottom: 1px solid #ccc;
      span {
        display: inline-block;
        width: 23%;
        margin-bottom: 5px;
      }
      & > span:nth-of-type(1) {
        flex: 1;
      }
    }
    .list {
      .l_item {
        display: flex;
        border-bottom: 1px solid #ccc;
        padding: 16px 0px;
        & > div {
          width: 23%;
          font-size: 14px;
        }
        & > div:nth-of-type(1) {
          flex: 1;
          padding-left: 15px;
        }
        .status{
          text-transform: capitalize;
        }
      }
      .gray {
        color: #a8a8a8;
      }
    }
  }
}
</style>
